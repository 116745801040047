import Navbar from "./components/Components-Navbar";
import Skill from "./page/Skill";
import Home from "./page/Home";
import FooterComponent from "./components/Components-Footer";

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <Skill />
      <FooterComponent />
    </>
  );
}

export default App;
