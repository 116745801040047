import React from "react";

function Dropdown() {
  return (
    <>
      <div className="mb-2 p-3 sm:p-4">
        <p className="font-bold text-base sm:text-lg lg:text-xl">TK-SD</p>
        <p className="text-white text-xs sm:text-sm lg:text-base">
          Saya lulusan TK Raudhatul Athfal selama 1 tahun, kemudian masuk
          sekolah SD 17 Singkawang dan lulus dalam 6 tahun.
        </p>
      </div>

      <div className="mb-2 p-3 sm:p-4 ">
        <p className="font-bold text-base sm:text-lg lg:text-xl">SMP</p>
        <p className="text-white text-xs sm:text-sm lg:text-base">
          Pada saat SMP, saya 2 kali pindah sekolah karena mengikuti orang tua
          pindah dinas. Pertama di SMP 1 Singkawang dari kelas 7-8, kemudian
          lanjut di SMP 19 Bandar Lampung hingga lulus.
        </p>
      </div>

      <div className="mb-2 p-3 sm:p-4 ">
        <p className="font-bold text-base sm:text-lg lg:text-xl">SMA</p>
        <p className="text-white text-xs sm:text-sm lg:text-base">
          Saat SMA, saya pindah dari Bandar Lampung ke Kota Serang, Banten, dan
          bersekolah di SMAN 1 Kota Serang hingga lulus.
        </p>
      </div>

      <div className="mb-2 p-3 sm:p-4 ">
        <p className="font-bold text-base sm:text-lg lg:text-xl">Kuliah</p>
        <p className="text-white text-xs sm:text-sm lg:text-base">
          Saya kuliah di Universitas Amikom Yogyakarta, S1 Jurusan Teknik
          Informatika, dari tahun 2019 hingga 2023.
        </p>
      </div>
    </>
  );
}

export default Dropdown;
